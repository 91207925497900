<template>
  <div
    class="odds-checker-table-row"
    :class="{ 'event-disabled': eventDisabled }"
  >
    <div
      :class="['odds-checker-table-row-column', column.type]"
      v-for="column in tableColumns"
      :key="column.id"
    >
      <oddsCheckerTableCell
        :column="column"
        :event="rowEvent"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import OddsCheckerTableCell from './OddsCheckerTableCell';

export default {
  components: {
    OddsCheckerTableCell,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    eventId: {
      type: String,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    isPlayerProps: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const rowEvent = computed(() => (props.isPlayerProps ? props.event : store.getters.oddsCheckerEventById(props.eventId)));
    const eventDisabled = computed(() => rowEvent.value?.limbo);
    return {
      rowEvent,
      eventDisabled,
    };
  },
};
</script>

<style lang="scss">
.odds-checker-table-row {
  width: 100%;
  height: 56px;
  display: flex;
  border-bottom: 1px solid #DDDDDD;
  min-width: fit-content;

  &:last-child {
    border-bottom: 0;
  }

  &:nth-child(even) {
    background-color: #FAFAFA;
  }

  &.event-disabled {
    background-color: $gray400;
    border-left: 4px solid $gray700;
  }

  .odds-checker-table-row-column {
    height: 100%;
    padding: 0 8px;
    color: #6D6D6D;
    display: flex;
    align-items: center;
    min-width: 270px;

    &.label-icon {
      width: 110px;
      max-width: 110px;
      min-width: 110px;
    }

    &.label-text {
      flex: 1;
      border-right: 1px solid #DDDDDD;
    }
    &.market {
      flex: 1;
      border-right: 1px solid #DDDDDD;
      padding: 0;
    }

    &:last-child {
      &.market {
        border-right: 0;
      }
    }
  }
}

</style>
